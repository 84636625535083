var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "frag",
      rawName: "v-frag"
    }]
  }, [_c('Parent'), _c('v-card', [_c('v-card-title', {
    staticClass: "py-5"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "label",
    attrs: {
      "text": "",
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "font-weight-regular secondary--text text-capitalize"
  }, [_vm._v(" ユーザー情報 ")])])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_c('div', {
    staticClass: "font-12px font-Roboto text-9b"
  }, [_vm._v("ID")])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "font-12px font-Roboto primary--text"
  }, [_vm._v(" " + _vm._s(_vm.getUserShop.id) + " ")])])], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_vm._l(_vm.fields, function (field) {
    return [_c('v-col', {
      key: "field-label-".concat(field.name),
      staticClass: "text-right",
      attrs: {
        "cols": "2"
      }
    }, [_c('div', {
      staticClass: "font-12px font-Roboto text-9b"
    }, [_vm._v(" " + _vm._s(field.label) + " ")])]), _c('v-col', {
      key: "field-value-".concat(field.name),
      attrs: {
        "cols": "4"
      }
    }, [field.name !== 'role' ? [_c('div', {
      staticClass: "text-6b6b7b font-12px font-Roboto"
    }, [_vm._v(" " + _vm._s(_vm.userShop[field.name]) + " ")])] : [_c('v-select', {
      staticClass: "text-6b6b7b font-12px font-Roboto",
      attrs: {
        "items": _vm.roles,
        "item-value": "value",
        "item-text": "text",
        "outlined": "",
        "hide-details": "",
        "dense": "",
        "disabled": !_vm.isDeletable
      },
      model: {
        value: _vm.form.user_role,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "user_role", $$v);
        },
        expression: "form.user_role"
      }
    })]], 2)];
  })], 2)], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-8"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "#790000",
      "loading": _vm.loading,
      "disabled": !_vm.isDeletable
    },
    on: {
      "click": _vm.deleteConfirmation
    }
  }, [_vm._v("削除")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "width": "101 ",
      "height": "31px",
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.editUser
    }
  }, [_vm._v(" 保存 ")]), _c('v-spacer')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }