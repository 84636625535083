<template>
  <div v-frag>
    <Parent />
    <v-card>
      <v-card-title class="py-5">
        <v-row>
          <v-col cols="auto">
            <v-btn class="label" text color="transparent">
              <div class="font-weight-regular secondary--text text-capitalize">
                ユーザー情報
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="2" class="text-right">
              <div class="font-12px font-Roboto text-9b">ID</div>
            </v-col>
            <v-col cols="3">
              <div class="font-12px font-Roboto primary--text">
                {{ getUserShop.id }}
              </div>
            </v-col>
          </v-row>

          <v-row align="center">
            <template v-for="field in fields">
              <v-col
                cols="2"
                class="text-right"
                :key="`field-label-${field.name}`"
              >
                <div class="font-12px font-Roboto text-9b">
                  {{ field.label }}
                </div>
              </v-col>
              <v-col cols="4" :key="`field-value-${field.name}`">
                <template v-if="field.name !== 'role'">
                  <div class="text-6b6b7b font-12px font-Roboto">
                    {{ userShop[field.name] }}
                  </div>
                </template>
                <template v-else>
                  <v-select
                    :items="roles"
                    v-model="form.user_role"
                    item-value="value"
                    item-text="text"
                    outlined
                    hide-details
                    dense
                    :disabled="!isDeletable"
                    class="text-6b6b7b font-12px font-Roboto"
                  >
                  </v-select>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-btn
          text
          color="#790000"
          :loading="loading"
          @click="deleteConfirmation"
          :disabled="!isDeletable"
          >削除</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2"
          width="101 "
          height="31px"
          color="primary"
          @click="editUser"
          :loading="loading"
        >
          保存
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Parent from "@/views/admin/Store/Index";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "EditUserShop",
  components: {
    Parent
  },
  computed: {
    ...mapGetters(["getShop", "getUserShop"]),
    userShop() {
      let user = this.getUserShop?.user;
      user.full_name = `${user?.surname} ${user?.name}`;
      user.full_furigana_name = `${user?.furigana_surname} ${user?.furigana_name}`;
      return user;
    },
    roles() {
      return [
        {
          value: "P",
          text: "パートユーザー"
        },
        {
          value: "M",
          text: "マスターユーザー"
        }
      ];
    },
    // Prevent user shop from being deleted if the user shop is the only M user left.
    isDeletable() {
      if (this.getUserShop.user_role === "P") return true;
      let MUsers = this.getShop?.user_shop?.filter(
        user => user.user_role === "M"
      );
      return MUsers.length !== 1;
    }
  },
  data() {
    return {
      fields: [
        {
          name: "full_name",
          label: "登録者名"
        },
        {
          name: "telephone_number",
          label: "電話番号"
        },
        {
          name: "full_furigana_name",
          label: "フリガナ"
        },
        {
          name: "shop_name",
          label: "会社名"
        },
        {
          name: "email",
          label: "メールアドレス(ID)"
        },
        {
          name: "position",
          label: "役職"
        },
        {
          name: "shop_name2",
          label: "会社名"
        },
        {
          name: "role",
          label: "権限"
        }
      ],
      form: {
        id: this.$route.params.user_id,
        shop_id: this.$route.params.shop_id,
        user_role: ""
      },
      loading: false
    };
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    ...mapMutations([
      "showModal",
      "hideModal",
      "startModalLoading",
      "stopModalLoading"
    ]),
    async getDataFromApi() {
      this.loading = true;
      let params = {
        shop_id: this.$route.params.shop_id,
        user_id: this.$route.params.user_id
      };

      await this.$store
        .dispatch("SHOP_USER_GET", params)
        .then(() => {
          this.form.user_role = this.getUserShop.user_role;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async editUser() {
      this.loading = true;
      this.form.user_id = this.getUserShop.user_id;
      await this.$store
        .dispatch("SHOP_USER_UPDATE", this.form)
        .then(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    deleteConfirmation() {
      this.showModal({
        title: "",
        text: "このユーザーを店舗ユーザーから\n削除してもよろしいですか？",
        action: {
          process: {
            color: "primary",
            text: "削除"
          },
          cancel: {
            color: "secondary",
            text: "キャンセル"
          }
        },
        func: this.processDelete
      });
    },

    async processDelete() {
      this.loading = true;
      let params = {
        shop_id: this.$route.params.shop_id,
        user_id: this.$route.params.user_id
      };
      await this.$store
        .dispatch("SHOP_USER_DELETE", params)
        .then(() => {
          this.$router.push({
            name: "ShopsEdit",
            params: { shop_id: this.$route.params.shop_id }
          });
        })
        .finally(() => {
          this.hideModal();
          this.stopModalLoading();
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .v-input {
    font-size: 12px !important;
    color: #6e6b7b;
  }
  .v-text-field {
    border-radius: 3px;

    &__slot {
      font-size: 12px !important;
    }
    &--outlined {
      & > .v-input__control {
        & > .v-input__slot {
          background: rgba(205, 205, 205, 0.15);
        }
      }
    }
  }
}
</style>
